<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <form autocomplete="off">
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          alt="Image"
          @click="selectImage"
        ></div>
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>

      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'cardSymbol'"
            :value="cardPlan.cardSymbol"
            v-on:changeValue="cardPlan.cardSymbol = $event"
            :title="$t('CardPlans.Symbol')"
            :imgName="'symbol.svg'"
          />
          <CustomInputInt
            :className="'col-md-6'"
            :id="'cardSerialNumberCount'"
            :value="cardPlan.cardSerialNumberCount"
            v-on:changeValue="cardPlan.cardSerialNumberCount = $event"
            :title="$t('CardPlans.SerialNumberCount')"
            :imgName="'number.svg'"
          />

          <CustomInputInt
            :className="'col-md-6'"
            :id="'cardStartNumber'"
            :value="cardPlan.cardStartNumber"
            v-on:changeValue="cardPlan.cardStartNumber = $event"
            :title="$t('CardPlans.StartNumber')"
            :imgName="'number.svg'"
          />
          <CustomInputInt
            :className="'col-md-6'"
            :id="'cardEndNumber'"
            :value="cardPlan.cardEndNumber"
            v-on:changeValue="cardPlan.cardEndNumber = $event"
            :title="$t('CardPlans.EndNumber')"
            :imgName="'number.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'cardCreationPlanDescriptionAr'"
            :value="cardPlan.cardCreationPlanDescriptionAr"
            v-on:changeValue="cardPlan.cardCreationPlanDescriptionAr = $event"
            :title="$t('CardPlans.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'cardCreationPlanDescriptionEn'"
            :value="cardPlan.cardCreationPlanDescriptionEn"
            v-on:changeValue="cardPlan.cardCreationPlanDescriptionEn = $event"
            :title="$t('CardPlans.descriptionEn')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'cardCreationPlanNotes'"
            :value="cardPlan.cardCreationPlanNotes"
            v-on:changeValue="cardPlan.cardCreationPlanNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addCardPlan"
        >
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'CardPlans' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "./../../components/general/PreLoader.vue";
import { BASE_URL } from "./../../utils/constants";
import { setDataMultiLang } from "./../../utils/functions";
import CustomInput from "./../../components/general/CustomInput.vue";
import CustomInputInt from "./../../components/general/CustomInputInt.vue";
import TextArea from "./../../components/general/TextArea.vue";
import createToastMixin from "./../../utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustomInput,
    CustomInputInt,
    TextArea,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "constantsListsData"]),
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      imageSrc: "",
      userTypeTokenOptions: [],
    };
  },
  props: ["cardPlan", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.cardPlan.mediaFile = file[0];
      }
    },
    async addCardPlan() {
      this.$emit("addCardPlan");
    },
    async getDialogOfUserTypes() {
      this.isLoading = true;
      this.userTypeTokenOptions = [];
      this.userTypeTokenOptions.push({
        value: "",
        text: this.$t("users.selectType"),
      });
      let genders = this.constantsListsData.listUserType;
      for (let item in genders) {
        this.userTypeTokenOptions.push({
          value: genders[item]["itemToken"],
          text: setDataMultiLang(
            this.language,
            genders[item]["itemNameAr"],
            genders[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.imageSrc = this.cardPlan.cardPlanImagePath
      ? BASE_URL + this.cardPlan.cardPlanImagePath
      : this.cardPlan.defaultImg;
    this.getDialogOfUserTypes();
  },
};
</script>

<style lang="scss"></style>
