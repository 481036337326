import axios from "axios";
import BaseEntityModel from "./../general/BaseEntityModel";
import CardPlansFilter from "./CardPlansFilter";
import CardPlansImg from "./../../assets/images/cardPlans.svg";
import { PAGE_SIZE, STATUS, BASE_URL } from "./../../utils/constants";
import i18n from "./../../i18n";
import store from "./../../store";

export default class CardPlan extends BaseEntityModel {
  defaultImg = CardPlansImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.cardCreationPlanToken = "";
    this.cardCreationPlanNameCurrent = "";
    this.cardCreationPlanNameEn = "";
    this.cardCreationPlanNameAr = "";
    this.cardCreationPlanNameUnd = "";
    this.cardSymbol = "";
    this.cardStartNumber = "";
    this.cardEndNumber = "";
    this.cardSerialNumberCount = "";
    this.cardCreationPlanDescriptionCurrent = "";
    this.cardCreationPlanDescriptionAr = "";
    this.cardCreationPlanDescriptionEn = "";
    this.cardCreationPlanDescriptionUnd = "";
    this.cardCreationPlanImagePath = "";
    this.cardCreationPlanNotes = "";
    this.cardCreationPlanArchiveStatus = false;
  }
  fillData(data) {
    this.fillDataBaseEntityModelBaseEntityModel(data);
    this.cardCreationPlanToken = data.cardCreationPlanToken;
    this.cardCreationPlanNameCurrent = data.cardCreationPlanNameCurrent;
    this.cardCreationPlanNameEn = data.cardCreationPlanNameEn;
    this.cardCreationPlanNameAr = data.cardCreationPlanNameAr;
    this.cardCreationPlanNameUnd = data.cardCreationPlanNameUnd;
    this.cardSymbol = data.cardSymbol;
    this.cardStartNumber = data.cardStartNumber;
    this.cardEndNumber = data.cardEndNumber;
    this.cardSerialNumberCount = data.cardSerialNumberCount;
    this.cardCreationPlanDescriptionCurrent =
      data.cardCreationPlanDescriptionCurrent;
    this.cardCreationPlanDescriptionAr = data.cardCreationPlanDescriptionAr;
    this.cardCreationPlanDescriptionEn = data.cardCreationPlanDescriptionEn;
    this.cardCreationPlanDescriptionUnd = data.cardCreationPlanDescriptionUnd;
    this.cardCreationPlanImagePath = data.cardCreationPlanImagePath;
    this.cardCreationPlanNotes = data.cardCreationPlanNotes;
    this.cardCreationPlanArchiveStatus = data.cardCreationPlanArchiveStatus;
  }

  async getAllCardPlans(language, userAuthorizeToken, pagination, filterData) {
    return await axios.get(
      `/api/CardCreationPlans/GetAllCardCreationPlans?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&pageSize=${PAGE_SIZE}&page=${pagination.selfPage}&filterStatus=true&textSearch=${filterData.textSearch}`
    );
  }

  async getCardPlanDialog(
    language,
    userAuthorizeToken,
    filterData = new CardPlansFilter()
  ) {
    let options = [];
    try {
      let response = await axios.get(
        `/api/CardCreationPlans/GetCardCreationPlanDialog?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&textSearch=${filterData.textSearch}`
      );
      const itemsData = response.data.itemsData;
      if (response.data.status == STATUS.SUCCESS) {
        for (let item in itemsData) {
          options.push({
            value: itemsData[item]["itemToken"],
            text: itemsData[item]["itemName"],
            image: itemsData[item]["itemImagePath"]
              ? BASE_URL + itemsData[item]["itemImagePath"]
              : CardPlansImg,
          });
        }
      } else if (response.data.status == STATUS.INVALID_TOKEN) {
        store.dispatch("logoutUser");
      } else {
        options.push({
          value: "",
          text: response.data.msg,
          image: CardPlansImg,
        });
      }
    } catch (error) {
      options.push({
        value: "",
        text: i18n.t("errorCatch"),
        image: CardPlansImg,
      });
    }
    return options;
  }

  async getCardPlanDetails(language, userAuthorizeToken) {
    return await axios.get(
      `/api/CardCreationPlans/GetCardCreationPlanDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&token=${this.cardCreationPlanToken}`
    );
  }

  async addCardPlan(language, userAuthorizeToken) {
    var formData = new FormData();
    formData.append("mediaFile", this.mediaFile);
    formData.append("language", language);
    formData.append("userAuthorizeToken", userAuthorizeToken);
    formData.append("cardSymbol", this.cardSymbol);
    formData.append("cardStartNumber", this.cardStartNumber);
    formData.append("cardEndNumber", this.cardEndNumber);
    formData.append("cardSerialNumberCount", this.cardSerialNumberCount);
    formData.append(
      "cardCreationPlanDescriptionAr",
      this.cardCreationPlanDescriptionAr
    );
    formData.append(
      "cardCreationPlanDescriptionEn",
      this.cardCreationPlanDescriptionEn
    );
    formData.append(
      "cardCreationPlanDescriptionUnd",
      this.cardCreationPlanDescriptionUnd
    );
    formData.append("cardCreationPlanNotes", this.cardCreationPlanNotes);

    return await axios.post(
      `/api/CardCreationPlans/AddCardCreationPlan`,
      formData
    );
  }

  async archiveCardPlan(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      token: this.cardCreationPlanToken,
      archiveStatus: true,
    };

    return await axios.post(
      `/api/CardCreationPlans/ArchiveCardCreationPlan`,
      data
    );
  }
}
